import './App.css';
import ChampionsLeagueTable from './components/tables/ChampionsLeagueTable'
import PremierLeagueTable from './components/tables/PremierLeagueTable';
import SerieATable from './components/tables/SerieATable';
import LaLigaTable from './components/tables/LaLigaTable';
import MajorLeagueSoccerTable from './components/tables/MajorLeagueSoccerTable';
import Banner from './Banner';
import Legend from './Legend';

function App() {
  return (
    <div className="App bg-gray-400">
      <Banner />
      <Legend />
      <ChampionsLeagueTable />
      <PremierLeagueTable />
      <SerieATable />
      <LaLigaTable />
      <MajorLeagueSoccerTable />
      <div className="bg-grey-100 h-10"></div>
    </div>
  );
}

export default App;
