import Players from '../../data/players/premierleague.json'
import Matches from '../../data/matches/premierleaguematches.json'
import LeagueTableHeader from '../LeagueTableHeader'
import LeagueTable from '../LeagueTable'
import TableCaption from '../TableCaption'
import MatchTable from '../MatchTable'

function PremierLeagueTable() {
    return (
        <div className="mt-10">
            <table className="league-table">
                <TableCaption title="Premier League" />
                <tbody className="league-table-body">
                    <LeagueTableHeader />
                    <LeagueTable players={Players} />
                </tbody>
            </table>
            <table className="match-table">
                <TableCaption title="Matches" />
                <tbody className="text-sm text-black-700">
                    <MatchTable matches={Matches} />
                </tbody>                                          
            </table>
        </div>
    )
}

export default PremierLeagueTable;