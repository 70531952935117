import Players from '../../data/players/majorleague.json'
import Matches from '../../data/matches/majorleaguematches.json'
import LeagueTableHeader from '../LeagueTableHeader'
import LeagueTable from '../LeagueTable'
import TableCaption from '../TableCaption'
import MatchTable from '../MatchTable'

function MajorLeagueSoccerTable() {
    return (
        <div className="mt-10">
            <table className="league-table">
                <TableCaption title="Major League Soccer"/>
                <tbody className="league-table-body">
                    <LeagueTableHeader />
                    <LeagueTable players={Players} />
                </tbody>
            </table>
            <table className="match-table">
                <TableCaption title="Matches"/>
                <tbody className="text-sm text-black-700">
                    <MatchTable matches={Matches} />
                </tbody>                                                   
            </table>
        </div>
    )
}

export default MajorLeagueSoccerTable;